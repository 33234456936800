import React from "react"
import Layout from "../components/layout"
import {
  container,
  date,
  title,
  horizontal_rule,
} from "../styles/index.module.css"
import { SEO } from "../components/seo"

const IndexPage = () => {
  return (
    <Layout pageTitle={"Wedding | Eames Maloney"}>
      <container className={container}>
        <p className={title}>👰‍♀️ Charlotte & Niall 🤵‍♂️</p>
        <hr className={horizontal_rule}></hr>
        <p className={date}>23</p>
        <p className={date}>09</p>
        <p className={date}>23</p>
        <hr className={horizontal_rule}></hr>
        <p className={title}>
          <a href="https://www.thefaversham.com/">The Faversham - Leeds</a>
        </p>
      </container>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <SEO />